import {Static, StaticDecode, TSchema} from "@sinclair/typebox";
import {Value} from "@sinclair/typebox/value";
// eslint-disable-next-line no-restricted-imports
import {Params, useParams} from "react-router-dom";
import {HTTPError} from "../api";

type TStringRecord<T extends TSchema> = Static<T> extends Readonly<Params> ? T : never;

export function useTypedParams<const T extends TSchema>(schema: TStringRecord<T>): StaticDecode<T> {
  const value = useParams();
  try {
    const decoded = Value.Decode(schema, value);
    return decoded;
  } catch {
    throw new HTTPError(new Response(null, {status: 404}));
  }
}
