/* eslint-disable no-restricted-globals */
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {Json} from "../api/jsonApi";
import {Static, StaticDecode, TSchema} from "@sinclair/typebox";
import {Value} from "@sinclair/typebox/value";

// List of callbacks to call when session storage is changed to allow
// components to synchronize with each other
const SESSION_STORAGE_WATCHERS = new Map<string, Set<() => void>>();

// Listen to storage changes from other windows
addEventListener("storage", event => {
  if (event.storageArea === sessionStorage) {
    const watchers =
      event.key == null
        ? [...SESSION_STORAGE_WATCHERS.values()].flatMap(cbs => [...cbs])
        : [...(SESSION_STORAGE_WATCHERS.get(event.key) ?? [])];
    for (const watcher of watchers) {
      watcher();
    }
  }
});

type TJson<T extends TSchema> = Static<T> extends Json ? T : never;

// Helper function to deserialize a state from session storage, and
// return a default value if the state is unset or otherwise fails
// to deserialize.
export function readSessionState<S extends TSchema>(
  storageKey: string,
  schema: TJson<S>,
  defaultValue: StaticDecode<S>,
): StaticDecode<S> {
  const storedValue = sessionStorage.getItem(storageKey);
  try {
    if (storedValue) {
      return Value.Decode(schema, JSON.parse(storedValue));
    } else {
      return defaultValue;
    }
  } catch (ex) {
    return defaultValue;
  }
}

// Helper function to serialize a state to session storage
export function writeSessionState<S extends TSchema>(storageKey: string, schema: TJson<S>, value: StaticDecode<S>) {
  // Save the new state
  sessionStorage.setItem(storageKey, JSON.stringify(Value.Encode(schema, value)));
  // Run any watchers to ensure all components receive the new state
  const watchers = SESSION_STORAGE_WATCHERS.get(storageKey);
  if (watchers) {
    for (const watcher of watchers) {
      watcher();
    }
  }
}

// Similar to `useState`, except that state is persisted in `sessionStorage` under
// the provided `storageKey`. State must be JSON serializable.
export function useSessionState<S extends TSchema>(
  storageKey: string,
  schema: TJson<S>,
  defaultValue: StaticDecode<S>,
): [StaticDecode<S>, Dispatch<SetStateAction<StaticDecode<S>>>] {
  // On state initialization, attempt to read the value from `sessionStorage`.
  const [state, setState] = useState<StaticDecode<S>>(() => readSessionState(storageKey, schema, defaultValue));

  // Register a session storage watcher for this storage key so we get notified
  // if another component modifies the `sessionStorage`.
  useEffect(() => {
    // Define a callback which updates our state based on the session storage
    const cb = () => {
      setState(readSessionState(storageKey, schema, defaultValue));
    };
    // When the effect runs, we add the watcher
    if (!SESSION_STORAGE_WATCHERS.has(storageKey)) {
      SESSION_STORAGE_WATCHERS.set(storageKey, new Set());
    }
    SESSION_STORAGE_WATCHERS.get(storageKey)!.add(cb);

    // In the effect destructor, we remove the watcher
    return () => {
      SESSION_STORAGE_WATCHERS.get(storageKey)!.delete(cb);
    };
  }, [storageKey, schema, defaultValue]);

  // This is the function we return to the caller to allow them to set the state
  const wrappedSetState = useCallback(
    // Mirroring `useState`, the argument can be either a state value or a state-updating function
    (newValue: SetStateAction<StaticDecode<S>>) => {
      // If the caller provided a function, then apply the function to the freshly-read state
      if (typeof newValue === "function") {
        newValue = (newValue as (oldValue: StaticDecode<S>) => StaticDecode<S>)(
          readSessionState(storageKey, schema, defaultValue),
        );
      }
      writeSessionState(storageKey, schema, newValue);
    },
    [storageKey, schema, defaultValue],
  );

  // Finally, return the state and state setter
  return [state, wrappedSetState];
}

export function clearSessionState() {
  sessionStorage.clear();
  for (const watchers of SESSION_STORAGE_WATCHERS.values()) {
    for (const watcher of watchers) {
      watcher();
    }
  }
}
