import {ScopeIdOrGlobal} from "../../Products/VendorToolkit/scopes";
import {
  CreateScope,
  Scope,
  ScopeId,
  ScopeMin,
  CreateScopeAxis,
  ScopeAxisMin,
  ScopeAxisId,
  OwnerId,
  Owner,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function listMin() {
  return await jsonApi.get<ScopeMin[]>("/vendor_toolkit/scopes/min");
}

export async function list() {
  return await jsonApi.get<Scope[]>("/vendor_toolkit/scopes");
}

export async function load(scopeIds: ScopeIdOrGlobal[]) {
  return await jsonApi.post<Scope[]>("/vendor_toolkit/scopes/load", scopeIds);
}

export async function create(scope: CreateScope) {
  const res = await jsonApi.post<ScopeMin>("/vendor_toolkit/scopes", scope);
  await invalidateQueries([q.vendorToolkit.scopes.filter()]);

  return res;
}

export async function updateName(scopeId: ScopeId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scopes/${scopeId}/name`, name);
  await invalidateQueries([q.vendorToolkit.scopes.filter()]);
  return res;
}

export async function getOwner(scopeId: ScopeIdOrGlobal) {
  const res = await jsonApi.get<Owner | null>(`/vendor_toolkit/scopes/${scopeId}/owner`);
  return res;
}

export async function assign(scopeId: ScopeIdOrGlobal, owner: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scopes/${scopeId}/assign`, owner);
  await invalidateQueries([q.vendorToolkit.scopes.filter()]);
  return res;
}

export async function listAxes() {
  return await jsonApi.get<ScopeAxisMin[]>("/vendor_toolkit/scope_axes");
}

export async function createAxis(axis: CreateScopeAxis) {
  const res = await jsonApi.post<ScopeAxisMin>("/vendor_toolkit/scope_axes", axis);
  await invalidateQueries([q.vendorToolkit.scopeAxes.filter()]);
  return res;
}

export async function updateAxisName(axisId: ScopeAxisId, name: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scope_axes/${axisId}/name`, name);
  await invalidateQueries([q.vendorToolkit.scopeAxes.filter()]);
  return res;
}

export async function updateAxisColor(axisId: ScopeAxisId, color: string) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scope_axes/${axisId}/color`, color);
  await invalidateQueries([q.vendorToolkit.scopeAxes.filter()]);
  return res;
}

export async function updateAccessControls(
  scopeId: ScopeId,
  {reader_ids, writer_ids}: {reader_ids: OwnerId[]; writer_ids?: OwnerId[]},
) {
  const res = await jsonApi.post<"ok">(`/vendor_toolkit/scopes/${scopeId}/access`, {reader_ids, writer_ids});
  await invalidateQueries([q.vendorToolkit.filter()]);
  return res;
}

export async function disableAccessControls(scopeId: ScopeId) {
  const res = await jsonApi.delete_<"ok">(`/vendor_toolkit/scopes/${scopeId}/access`);
  await invalidateQueries([q.vendorToolkit.filter()]);
  return res;
}
