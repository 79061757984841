import {
  CreateAssessment,
  Assessment,
  AssessmentId,
  RiskMin,
  FilterAssessments,
  OwnerId,
  AssessmentStatus,
  AssessmentInvalidation,
  AssessmentInvalidationId,
  TprmReportTemplateId,
} from "../../Types";
import jsonApi, {addAccountId} from "../jsonApi";
import {invalidateQueries, q} from "../../state";

export async function search(filter: FilterAssessments): Promise<Assessment[]> {
  return await jsonApi.post<Assessment[]>(`/tprm/assessments/search`, filter);
}

export async function get(assessmentId: AssessmentId): Promise<Assessment> {
  return await jsonApi.get<Assessment>(`/tprm/assessments/${assessmentId}`);
}

export async function create(assessment: CreateAssessment): Promise<Assessment> {
  const res = await jsonApi.post<Assessment>("/tprm/assessments", assessment);
  await invalidateQueries([q.tprm.thirdPartyAssessments.filter(assessment.third_party_id)]);
  return res;
}

export async function reassess(assessmentId: AssessmentId): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/assessments/${assessmentId}/reassess`);
  await invalidateQueries([q.tprm.thirdPartyAssessments.filter(), q.tprm.assessment.filter()]);
  return res;
}

export async function listRisks(assessmentId: AssessmentId): Promise<RiskMin[]> {
  return await jsonApi.get<RiskMin[]>(`/tprm/assessments/${assessmentId}/risks`);
}

export async function assign(assessmentId: AssessmentId, owner: OwnerId | null): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/assessments/${assessmentId}/owner`, owner);
  await invalidateQueries([q.tprm.thirdPartyAssessments.filter(), q.tprm.assessment.filter()]);
  return res;
}

export async function updateStatus(assessmentId: AssessmentId, status: AssessmentStatus): Promise<"ok"> {
  const res = await jsonApi.post<"ok">(`/tprm/assessments/${assessmentId}/status`, status);
  await invalidateQueries([q.tprm.thirdPartyAssessments.filter(), q.tprm.assessment.filter()]);
  return res;
}

export async function listInvalidations(assessmentId: AssessmentId): Promise<AssessmentInvalidation[]> {
  return await jsonApi.get<AssessmentInvalidation[]>(`/tprm/assessments/${assessmentId}/invalidations`);
}

export async function ignoreInvalidation(
  assessmentId: AssessmentId,
  invalidationId: AssessmentInvalidationId,
): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(`/tprm/assessments/${assessmentId}/invalidations/${invalidationId}`);
  await invalidateQueries([
    q.tprm.assessment.filter(assessmentId),
    q.tprm.assessmentInvalidations.filter(assessmentId),
    q.tprm.risk.filter(),
    q.tprm.assessedControl.filter(assessmentId),
  ]);
  return res;
}

export const downloadReport = async (assessmentId: AssessmentId, reportTemplateId: TprmReportTemplateId) => {
  const a = document.createElement("a");
  a.href = addAccountId(`/api/tprm/assessments/${assessmentId}/report/${reportTemplateId}/download`);
  a.click();
};

export const getReportUrl = (assessmentId: AssessmentId, reportTemplateId: TprmReportTemplateId) => {
  return addAccountId(`/api/tprm/assessments/${assessmentId}/report/${reportTemplateId}`);
};
