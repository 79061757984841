import {
  CreateNotificationSink,
  NotificationSink,
  NotificationSinkMin,
  NotificationSinkConnector,
  NotificationSinkConnectorType,
  LinkNotificationSink,
  LinkedNotificationSinkMin,
  NotificationSinkConfig,
  UpdateNotificationSink,
  ExternalAuthorizationId,
  NotificationSinkId,
} from "../../Types.ts";
import jsonApi from "../jsonApi.ts";
import {invalidateQueries, q} from "../../state";

export async function listConnectors(): Promise<NotificationSinkConnector[]> {
  return await jsonApi.get<NotificationSinkConnector[]>("/notification_channel_connectors");
}

export async function getConnector(connectorType: NotificationSinkConnectorType): Promise<NotificationSinkConnector> {
  return await jsonApi.get<NotificationSinkConnector>(`/notification_channel_connectors/${connectorType}`);
}

export async function getDefaultConfig(
  connectorType: NotificationSinkConnectorType,
  externalAuthorizationId: ExternalAuthorizationId,
): Promise<NotificationSinkConfig> {
  return await jsonApi.post<NotificationSinkConfig>(
    `/notification_channel_connectors/${connectorType}/default_config`,
    externalAuthorizationId,
  );
}

export async function create(payload: CreateNotificationSink): Promise<NotificationSink> {
  const res = await jsonApi.post<NotificationSink>("/notification_channels", payload);
  await invalidateQueries([q.notificationChannels.filter()]);
  return res;
}

export async function update(channelId: NotificationSinkId, payload: UpdateNotificationSink) {
  const res = await jsonApi.post<"ok">(`/notification_channels/${channelId}`, payload);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function delete_(channelId: NotificationSinkId) {
  const res = await jsonApi.delete_<"ok">(`/notification_channels/${channelId}`);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function updateName(channelId: NotificationSinkId, name: string) {
  const res = await jsonApi.post<"ok">(`/notification_channels/${channelId}/name`, name);
  await invalidateQueries([q.notificationChannels.filter(), q.notificationChannel.filter(channelId)]);
  return res;
}

export async function list(): Promise<NotificationSinkMin[]> {
  return await jsonApi.get<NotificationSinkMin[]>("/notification_channels");
}

export async function get(channelId: NotificationSinkId): Promise<NotificationSink> {
  return await jsonApi.get<NotificationSink>(`/notification_channels/${channelId}`);
}

export async function listAvailable(): Promise<NotificationSinkMin[]> {
  return await jsonApi.get<NotificationSinkMin[]>("/available_notification_channels");
}

export async function linkChannel(link: LinkNotificationSink): Promise<LinkedNotificationSinkMin> {
  const res = await jsonApi.post<LinkedNotificationSinkMin>("/user_notification_channels", link);
  await invalidateQueries([q.availableNotificationChannels.filter(), q.linkedNotificationChannels.filter()]);
  return res;
}

export async function listLinked(): Promise<LinkedNotificationSinkMin[]> {
  return await jsonApi.get<LinkedNotificationSinkMin[]>("/user_notification_channels");
}

export async function deleteLinked(channelId: NotificationSinkId) {
  const res = await jsonApi.delete_<"ok">(`/user_notification_channels/${channelId}`);
  await invalidateQueries([q.availableNotificationChannels.filter(), q.linkedNotificationChannels.filter()]);
  return res;
}
