import {redirect} from "react-router-dom";
import {router} from "../router";
import isExtension from "../isExtension";
import {getFrontendEnvironment} from "./environment";
import {clearSessionState, readSessionState, writeSessionState} from "../hooks/sessionState";
import {Type} from "../typing";
import {Value} from "@sinclair/typebox/value";

export enum RedirectTarget {
  Unknown,
  Login,
  Register,
  AccountSelector,
  ProductSelector,
}

const redirectBase = isExtension ? "/extension" : "";

// Subclass `Response` to add an easy to use "follow()" method
export class RedirectResponse extends Response {
  #target: RedirectTarget;

  constructor(url: string, target = RedirectTarget.Unknown) {
    const r = redirect(url);
    super(null, {status: r.status, headers: r.headers});
    this.#target = target;
  }
  get target(): RedirectTarget {
    return this.#target;
  }
  get location(): string {
    return this.headers.get("Location")!;
  }
  follow() {
    const loc = this.location;
    if (loc && loc.startsWith("/") && !loc.startsWith("//")) {
      return router!.navigate(this.location, {replace: true});
    } else {
      window.location.replace(loc);
    }
  }
}

function canRedirectTo(path: string): boolean {
  const disallowedPrefixes = ["/login", "/register", "/logout", "/account-selector", "/request-reset", "/reset-login"];
  return (
    disallowedPrefixes.every(prefix => !path.startsWith(redirectBase + prefix)) && path !== "/" && path !== redirectBase
  );
}

export function getRedirectParams(): URLSearchParams {
  const url = new URL(window.location.href);
  // Handle case where user is in the process of navigating to a new URL
  if (router!.state.navigation.state === "loading") {
    const {pathname, search, hash} = router!.state.navigation.location;
    url.pathname = pathname;
    url.search = search;
    url.hash = hash;
  }
  // Preserve the location the user was trying to get to
  const safeRedirectPath = url.searchParams.get("redirect") ?? url.pathname + url.search + url.hash;
  const redirectUrlParams = new URLSearchParams();

  // Don't allow redirecting to login or logout pages as this would be very confusing
  // and could result in a cycle
  if (canRedirectTo(safeRedirectPath)) {
    redirectUrlParams.set("redirect", safeRedirectPath);
  }
  return redirectUrlParams;
}

export function shouldShowRegistrationLink() {
  return !!getRedirectParams().get("redirect")?.startsWith("/consent");
}

function getAuthenticationAction(): string {
  return new URL(window.location.href).searchParams.get("auth_action") ?? "";
}

function redirectPreservingUrl(location: string, target: RedirectTarget) {
  return new RedirectResponse(`${location}?${getRedirectParams()}`, target);
}

export function redirectRestoringUrl() {
  const redirectUrl = getRedirectParams().get("redirect");
  if (redirectUrl && redirectUrl.startsWith("/") && !redirectUrl.startsWith("//")) {
    return new RedirectResponse(redirectUrl, RedirectTarget.Unknown);
  } else {
    return new RedirectResponse("/", RedirectTarget.Unknown);
  }
}

export function redirectUnauthorized() {
  if (getAuthenticationAction() === "register") {
    return redirectToRegister();
  } else {
    return redirectToLogin();
  }
}

export function redirectToConsent(authAction: "login" | "register" = "login") {
  const {app_url} = getFrontendEnvironment();
  const params = new URLSearchParams({external_redirect: window.location.href, auth_action: authAction});
  window.location.assign(`${app_url}/consent?${params}`);
}

function redirectToLogin() {
  return redirectPreservingUrl(redirectBase + "/login", RedirectTarget.Login);
}

function redirectToRegister() {
  return redirectPreservingUrl(redirectBase + "/register", RedirectTarget.Register);
}

export function redirectToAccountSelector() {
  clearSessionState();
  return redirectPreservingUrl(redirectBase + "/account-selector", RedirectTarget.AccountSelector);
}

export function redirectToProductSelector() {
  clearSessionState();
  return redirectPreservingUrl(redirectBase + "/product-selector", RedirectTarget.ProductSelector);
}

const accountIdSchema = Type.Union([Type.Null(), Type.AccountId()]);

export function getAccountId() {
  // Allow "account_id" to be specified in URL
  const url = new URL(window.location.href);
  const accountId = Value.Decode(accountIdSchema, url.searchParams.get("account_id"));
  if (accountId) {
    // Account ID was specified, put it in session storage and then remove it
    // from the URL
    writeSessionState("account_id", accountIdSchema, accountId);
    url.searchParams.delete("account_id");
    window.history.replaceState({}, document.title, url);
  }
  return readSessionState("account_id", accountIdSchema, null);
}

export function getOneTimeToken() {
  const url = new URL(window.location.href);
  return url.searchParams.get("token");
}
