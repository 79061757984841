import * as batshit from "@yornaath/batshit";
import {
  AssessedControl,
  AssessedControlIdPair,
  AssessmentId,
  ControlEvidenceId,
  ControlId,
  ThirdPartyId,
  nominate,
} from "../../../Types";
import api, {HTTPError} from "../../../api";
import {param, typedQuery} from "../../typing";
import {resolver, resolverFn, scheduler} from "../../batch";

function encodeIdPair(idPair: AssessedControlIdPair): string {
  return `${idPair.assessment_id}/${idPair.control_id}`;
}
function decodeIdPair(value: string): AssessedControlIdPair {
  const [assessmentId, controlId] = value.split("/", 2);
  return {assessment_id: nominate("assessmentId", assessmentId), control_id: nominate("controlId", controlId)};
}

const assessedControls = batshit.create({
  fetcher: (queries: string[]) => api.tprm.assessedControls.load(queries.map(decodeIdPair)),
  resolver: resolverFn((assessedControl: AssessedControl, idPair) => encodeIdPair(assessedControl) === idPair),
  scheduler,
});

const controlEvidences = batshit.create({
  fetcher: api.tprm.assessedControls.loadEvidence,
  resolver: resolver("evidence_id"),
  scheduler,
});

export const assessment = typedQuery(
  ["tprm", "assessment", param<AssessmentId>("assessmentId")],
  async assessmentId => {
    return await api.tprm.assessments.get(assessmentId);
  },
);

export const assessmentRisks = typedQuery(
  ["tprm", "assessmentRisks", param<AssessmentId>("assessmentId")],
  async assessmentId => {
    return await api.tprm.assessments.listRisks(assessmentId);
  },
);

export const thirdPartyAssessments = typedQuery(
  ["tprm", "thirdPartyAssessments", param<ThirdPartyId>("thirdPartyId")],
  async thirdPartyId => {
    return await api.tprm.assessments.search({third_party_id: thirdPartyId});
  },
);

export const assessedControl = typedQuery(
  ["tprm", "assessedControl", param<AssessmentId>("assessmentId"), param<ControlId>("controlId")],
  async (assessmentId, controlId) => {
    try {
      return await assessedControls.fetch(encodeIdPair({assessment_id: assessmentId, control_id: controlId}));
    } catch (error) {
      if (error instanceof HTTPError && error.response.status === 404) {
        return null;
      }
      throw error;
    }
  },
);

export const controlEvidence = typedQuery(
  ["tprm", "controlEvidence", param<ControlEvidenceId>("evidenceId")],
  async evidenceId => {
    return await controlEvidences.fetch(evidenceId);
  },
);

export const assessmentInvalidations = typedQuery(
  ["tprm", "assessmentInvalidations", param<AssessmentId>("assessmentId")],
  async assessmentId => {
    return await api.tprm.assessments.listInvalidations(assessmentId);
  },
);
