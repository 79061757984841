import {QueryFilters, matchQuery} from "@tanstack/react-query";
import {queryClient} from ".";
import {noop} from "lodash-es";

let pendingInvalidations: (QueryFilters | undefined)[] = [];
export function directlyInvalidateQuery(filters: QueryFilters | undefined): void {
  const cache = queryClient.getQueryCache();
  cache.findAll(filters).forEach(query => {
    query.invalidate();
  });
  pendingInvalidations.push(filters);
}

function refetchInvalid(): Promise<void> {
  const cache = queryClient.getQueryCache();
  const promises = cache
    .getAll()
    .filter(
      query =>
        !query.isDisabled() &&
        pendingInvalidations.some(invalidation => !invalidation || matchQuery(invalidation, query)),
    )
    .map(query => {
      const promise = query.fetch().catch(noop);
      return query.state.fetchStatus === "paused" ? Promise.resolve() : promise;
    });
  pendingInvalidations = [];

  return Promise.all(promises).then(noop);
}

let bulkUpdateCount = 0;

export async function performBulkUpdate<R>(f: () => Promise<R>): Promise<R> {
  try {
    bulkUpdateCount += 1;
    return await f();
  } finally {
    bulkUpdateCount -= 1;
    if (bulkUpdateCount === 0) {
      await refetchInvalid();
    }
  }
}

export async function deferInvalidation(f: () => Promise<any>): Promise<void> {
  await f();
  if (bulkUpdateCount == 0) {
    await refetchInvalid();
  }
}
