import {useToast} from "@chakra-ui/react";
import {useEffect} from "react";
import {TOAST_TRIGGER_STORAGE_KEY} from ".";
import {readSessionState, writeSessionState} from "../../hooks/sessionState";
import {Type} from "../../typing";

// If we try to show the toast immediately it doesn't work for some reason...
const TOAST_DELAY = 3000;

export function AppUpdatedMessage() {
  const toast = useToast();
  useEffect(() => {
    if (readSessionState(TOAST_TRIGGER_STORAGE_KEY, Type.Boolean(), false)) {
      writeSessionState(TOAST_TRIGGER_STORAGE_KEY, Type.Boolean(), false);
      setTimeout(() => {
        toast({description: "The application was reloaded because it was out of date.", isClosable: true});
      }, TOAST_DELAY);
    }
  }, [toast]);
  return null;
}
