import {CreateTprmExternalQuestionnaire, TprmExternalQuestionnaire, TprmExternalQuestionnaireId} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function create(
  externalQuestionnaire: CreateTprmExternalQuestionnaire,
): Promise<TprmExternalQuestionnaire> {
  const res = await jsonApi.post<TprmExternalQuestionnaire>("/tprm/external_questionnaires", externalQuestionnaire);
  await invalidateQueries([q.tprm.thirdPartyExternalQuestionnaires.filter(externalQuestionnaire.third_party_id)]);
  return res;
}

export async function get(externalQuestionnaireId: TprmExternalQuestionnaireId): Promise<TprmExternalQuestionnaire> {
  return jsonApi.get<TprmExternalQuestionnaire>(`/tprm/external_questionnaires/${externalQuestionnaireId}`);
}

export async function unlock(externalQuestionnaireId: TprmExternalQuestionnaireId): Promise<void> {
  await jsonApi.post(`/tprm/external_questionnaires/${externalQuestionnaireId}/unlock`);
  await invalidateQueries([q.tprm.externalQuestionnaire.filter(externalQuestionnaireId)]);
}

export async function lock(externalQuestionnaireId: TprmExternalQuestionnaireId): Promise<void> {
  await jsonApi.post(`/tprm/external_questionnaires/${externalQuestionnaireId}/lock`);
  await invalidateQueries([q.tprm.externalQuestionnaire.filter(externalQuestionnaireId)]);
}
