import {TSchema, FormatRegistry, Type as BaseType, JavaScriptTypeBuilder} from "@sinclair/typebox";
import {
  AccountId,
  ActivityStreamId,
  AssetId,
  BackgroundTaskId,
  CounterpartyId,
  DealId,
  DocumentId,
  DocumentationId,
  ExternalAuthorizationId,
  FileId,
  OwnerId,
  QuestionId,
  QuestionnaireId,
  RoleId,
  SectionId,
  StandaloneFileId,
  TeamId,
  UserId,
  Uuid,
  ApikeyId,
  AssessmentId,
  ContentId,
  ControlGroupId,
  ControlId,
  CoreResponseId,
  CrmConnectionId,
  DocumentAccessRequestId,
  DocumentSourceId,
  EntityId,
  ErrorCatalogId,
  EsignatureProviderId,
  EsignatureTemplateId,
  FactClusterId,
  FactId,
  FactTextId,
  FrameworkId,
  FrameworkVersionId,
  LibrarySectionId,
  MailSubscriptionId,
  MailingListId,
  NotificationSinkId,
  QuestionSourceId,
  ResolutionId,
  ScopeAxisId,
  ScopeId,
  SourceChunkId,
  SourceId,
  ThirdPartyId,
  ShowcaseUpdateMessageId,
  TprmExternalQuestionnaireId,
  TprmQuestionnaireTemplateId,
  TprmReportTemplateId,
  RiskId,
  DataRoomId,
} from "../Types";

// Typebox does not come with any formats out of the box
const UuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

FormatRegistry.Set("uuid", (value: string) => UuidRegex.test(value));

const GenericUuid = BaseType.String({format: "uuid"});

function NominalType<U>(baseType: TSchema) {
  return BaseType.Unsafe<U>(baseType);
}

export class PlatformedTypeBuilder extends JavaScriptTypeBuilder {
  Uuid() {
    return NominalType<Uuid>(GenericUuid);
  }
  UserId() {
    return NominalType<UserId>(GenericUuid);
  }
  OwnerId() {
    return NominalType<OwnerId>(GenericUuid);
  }
  QuestionId() {
    return NominalType<QuestionId>(GenericUuid);
  }
  SectionId() {
    return NominalType<SectionId>(GenericUuid);
  }
  QuestionnaireId() {
    return NominalType<QuestionnaireId>(GenericUuid);
  }
  AccountId() {
    return NominalType<AccountId>(GenericUuid);
  }
  CounterpartyId() {
    return NominalType<CounterpartyId>(GenericUuid);
  }
  DocumentId() {
    return NominalType<DocumentId>(GenericUuid);
  }
  FileId() {
    return NominalType<FileId>(GenericUuid);
  }
  StandaloneFileId() {
    return NominalType<StandaloneFileId>(GenericUuid);
  }
  DocumentationId() {
    return NominalType<DocumentationId>(GenericUuid);
  }
  TeamId() {
    return NominalType<TeamId>(GenericUuid);
  }
  RoleId() {
    return NominalType<RoleId>(GenericUuid);
  }
  DealId() {
    return NominalType<DealId>(GenericUuid);
  }
  ExternalAuthorizationId() {
    return NominalType<ExternalAuthorizationId>(GenericUuid);
  }
  AssetId() {
    return NominalType<AssetId>(GenericUuid);
  }
  ActivityStreamId() {
    return NominalType<ActivityStreamId>(GenericUuid);
  }
  BackgroundTaskId() {
    return NominalType<BackgroundTaskId>(GenericUuid);
  }
  ApikeyId() {
    return NominalType<ApikeyId>(GenericUuid);
  }
  FactTextId() {
    return NominalType<FactTextId>(GenericUuid);
  }
  FactClusterId() {
    return NominalType<FactClusterId>(GenericUuid);
  }
  ScopeId() {
    return NominalType<ScopeId>(GenericUuid);
  }
  ScopeAxisId() {
    return NominalType<ScopeAxisId>(GenericUuid);
  }
  EntityId() {
    return NominalType<EntityId>(GenericUuid);
  }
  ContentId() {
    return NominalType<ContentId>(GenericUuid);
  }
  ResolutionId() {
    return NominalType<ResolutionId>(GenericUuid);
  }
  ErrorCatalogId() {
    return NominalType<ErrorCatalogId>(GenericUuid);
  }
  DocumentAccessRequestId() {
    return NominalType<DocumentAccessRequestId>(GenericUuid);
  }
  CrmConnectionId() {
    return NominalType<CrmConnectionId>(GenericUuid);
  }
  DocumentSourceId() {
    return NominalType<DocumentSourceId>(GenericUuid);
  }
  EsignatureProviderId() {
    return NominalType<EsignatureProviderId>(GenericUuid);
  }
  EsignatureTemplateId() {
    return NominalType<EsignatureTemplateId>(GenericUuid);
  }
  NotificationSinkId() {
    return NominalType<NotificationSinkId>(GenericUuid);
  }
  QuestionSourceId() {
    return NominalType<QuestionSourceId>(GenericUuid);
  }
  LibrarySectionId() {
    return NominalType<LibrarySectionId>(GenericUuid);
  }
  FactId() {
    return NominalType<FactId>(GenericUuid);
  }
  CoreResponseId() {
    return NominalType<CoreResponseId>(GenericUuid);
  }
  FrameworkId() {
    return NominalType<FrameworkId>(GenericUuid);
  }
  FrameworkVersionId() {
    return NominalType<FrameworkVersionId>(GenericUuid);
  }
  ThirdPartyId() {
    return NominalType<ThirdPartyId>(GenericUuid);
  }
  AssessmentId() {
    return NominalType<AssessmentId>(GenericUuid);
  }
  ControlId() {
    return NominalType<ControlId>(GenericUuid);
  }
  ControlGroupId() {
    return NominalType<ControlGroupId>(GenericUuid);
  }
  SourceId() {
    return NominalType<SourceId>(GenericUuid);
  }
  SourceChunkId() {
    return NominalType<SourceChunkId>(GenericUuid);
  }
  MailingListId() {
    return NominalType<MailingListId>(GenericUuid);
  }
  ShowcaseUpdateMessageId() {
    return NominalType<ShowcaseUpdateMessageId>(GenericUuid);
  }
  MailSubscriptionId() {
    return NominalType<MailSubscriptionId>(GenericUuid);
  }
  TprmExternalQuestionnaireId() {
    return NominalType<TprmExternalQuestionnaireId>(GenericUuid);
  }
  TprmQuestionnaireTemplateId() {
    return NominalType<TprmQuestionnaireTemplateId>(GenericUuid);
  }
  TprmReportTemplateId() {
    return NominalType<TprmReportTemplateId>(GenericUuid);
  }
  RiskId() {
    return NominalType<RiskId>(GenericUuid);
  }
  DataRoomId() {
    return NominalType<DataRoomId>(GenericUuid);
  }
  JsonDate() {
    return this.Transform(this.Number())
      .Decode(value => new Date(value))
      .Encode(value => value.getTime());
  }
}
export const Type = new PlatformedTypeBuilder();
