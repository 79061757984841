import {
  Risk,
  RiskId,
  RecordRiskEvaluation,
  OwnerId,
  RiskContextId,
  RiskContext,
  ControlId,
  CreateRiskContext,
  AssessmentId,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import jsonApi from "../jsonApi";

export async function load(riskIds: RiskId[]) {
  return await jsonApi.post<(Risk | null)[]>(`/tprm/risks/load`, riskIds);
}

export async function evaluateInherentLevel(riskId: RiskId, evaluation: RecordRiskEvaluation) {
  const res = await jsonApi.post<"ok">(`/tprm/risks/${riskId}/inherent_risk`, evaluation);
  await invalidateQueries([q.tprm.risk.filter(riskId), q.tprm.assessmentInvalidations.filter()]);
  return res;
}

export async function evaluateResidualLevel(riskId: RiskId, evaluation: RecordRiskEvaluation) {
  const res = await jsonApi.post<"ok">(`/tprm/risks/${riskId}/residual_risk`, evaluation);
  await invalidateQueries([q.tprm.risk.filter(riskId), q.tprm.assessmentInvalidations.filter()]);
  return res;
}

export async function updateAcceptance(riskId: RiskId, accepted: boolean) {
  const res = await jsonApi.post<"ok">(`/tprm/risks/${riskId}/accepted`, accepted);
  await invalidateQueries([q.tprm.risk.filter(riskId), q.tprm.assessmentInvalidations.filter()]);
  return res;
}

export async function assign(riskId: RiskId, ownerId: OwnerId | null) {
  const res = await jsonApi.post<"ok">(`/tprm/risks/${riskId}/owner`, ownerId);
  await invalidateQueries([q.tprm.risk.filter(riskId)]);
  return res;
}

export async function loadContexts(contextIds: RiskContextId[]) {
  return await jsonApi.post<(RiskContext | null)[]>(`/tprm/risk_contexts/load`, contextIds);
}

export async function linkRelevantControl(riskId: RiskId, controlId: ControlId) {
  const res = await jsonApi.put<"ok">(`/tprm/risks/${riskId}/relevant_controls/${controlId}`);
  await invalidateQueries([
    q.tprm.risk.filter(riskId),
    q.tprm.assessedControl.filter(),
    q.tprm.assessmentInvalidations.filter(),
  ]);
  return res;
}

export async function unlinkRelevantControl(riskId: RiskId, controlId: ControlId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/risks/${riskId}/relevant_controls/${controlId}`);
  await invalidateQueries([
    q.tprm.risk.filter(riskId),
    q.tprm.assessedControl.filter(),
    q.tprm.assessmentInvalidations.filter(),
  ]);
  return res;
}

export async function addContext(riskId: RiskId, context: CreateRiskContext) {
  const res = await jsonApi.post<RiskContextId>(`/tprm/risks/${riskId}/contexts`, context);
  await invalidateQueries([q.tprm.risk.filter(riskId)]);
  return res;
}

export async function removeContext(riskId: RiskId, contextId: RiskContextId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/risks/${riskId}/contexts/${contextId}`);
  await invalidateQueries([q.tprm.risk.filter(riskId)]);
  return res;
}

export async function delete_(riskId: RiskId, assessmentId: AssessmentId) {
  const res = await jsonApi.delete_<"ok">(`/tprm/risks/${riskId}`);
  await invalidateQueries([q.tprm.assessmentRisks.filter(assessmentId)]);
  return res;
}
