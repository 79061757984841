import {invalidateQueries, q} from "../state/index.ts";
import {ReminderSchedule} from "../Types.ts";
import jsonApi from "./jsonApi.ts";

export async function get_(): Promise<ReminderSchedule> {
  return await jsonApi.get<ReminderSchedule>(`/reminder_schedule`);
}

export async function update(reminderSchedule: ReminderSchedule): Promise<ReminderSchedule> {
  const res = await jsonApi.put<ReminderSchedule>(`/reminder_schedule`, reminderSchedule);
  await invalidateQueries([q.reminderSchedule.filter()]);
  return res;
}
