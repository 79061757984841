import {
  AccountId,
  FileId,
  TprmExternalQuestionnaireExternal,
  TprmExternalQuestionnaireId,
  TprmQuestionTemplateId,
  File as FileT,
  TprmQuestionnaireTemplate,
  RegisteredUserMin,
} from "../../Types";
import {invalidateQueries, q} from "../../state";
import {uploadImpl} from "../files";
import jsonApi, {API_BASE} from "./jsonApi";

export async function getExternalQuestionnaire(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
) {
  return await jsonApi.get<TprmExternalQuestionnaireExternal>(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}`,
  );
}

export async function getExternalQuestionnaireTemplate(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
) {
  return await jsonApi.get<TprmQuestionnaireTemplate>(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/template`,
  );
}

export async function updateTextAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
  text: string,
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/update_text`,
    text,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);
  return res;
}

export async function updateSelectAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
  values: string[],
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/update_select`,
    values,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);

  return res;
}

export async function addFilesToAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
  fileIds: FileId[],
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/add_files`,
    fileIds,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);

  return res;
}

export async function removeFileFromAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
  fileId: FileId,
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/remove_file`,
    fileId,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);

  return res;
}

export async function addSubQuestionnaireToAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
) {
  const res = await jsonApi.post<TprmExternalQuestionnaireId>(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/add_sub_questionnaire`,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);

  return res;
}

export async function removeSubQuestionnaireFromAnswer(
  accountId: AccountId,
  rootQuestionnaireId: TprmExternalQuestionnaireId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  questionTemplateId: TprmQuestionTemplateId,
  subExternalQuestionnaireId: TprmExternalQuestionnaireId,
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/question/${questionTemplateId}/remove_sub_questionnaire`,
    subExternalQuestionnaireId,
  );

  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, rootQuestionnaireId)]);

  return res;
}

export function uploadFile(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,

  file: File,
  progressFn?: (progress: number) => void,
  signal?: AbortSignal,
): Promise<FileT> {
  return uploadImpl(
    `${API_BASE}/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/files`,
    file,
    progressFn,
    signal,
  );
}

export function downloadFile(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  fileId: FileId,
) {
  jsonApi.download(`/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/files/${fileId}`);
}

export async function submitExternalQuestionnaire(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/submit`,
  );
  await invalidateQueries([q.external.collect.externalQuestionnaire.filter(accountId, externalQuestionnaireId)]);
  return res;
}

export async function acceptExternalQuestionnaireInvite(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
  inviteKey: string,
) {
  const res = await jsonApi.post<"ok">(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/accept_invite`,
    inviteKey,
  );
  await invalidateQueries([
    q.external.collect.externalQuestionnaireAccessible.filter(accountId, externalQuestionnaireId),
  ]);
  return res;
}

export async function listExternalQuestionnaireUsers(
  accountId: AccountId,
  externalQuestionnaireId: TprmExternalQuestionnaireId,
) {
  return await jsonApi.get<RegisteredUserMin[]>(
    `/accounts/${accountId}/collect/questionnaire/${externalQuestionnaireId}/users`,
  );
}
