import {invalidateQueries, q} from "../../state";
import {CreateTprmReportTemplate, TprmReportTemplate, TprmReportTemplateId} from "../../Types";
import jsonApi from "../jsonApi";

export async function list(): Promise<TprmReportTemplate[]> {
  return jsonApi.get("/tprm/report_templates");
}

export async function create(reportTemplate: CreateTprmReportTemplate): Promise<TprmReportTemplateId> {
  const res = await jsonApi.post<TprmReportTemplateId>("/tprm/report_templates", reportTemplate);
  await invalidateQueries([q.tprm.reportTemplates.filter()]);
  return res;
}

export async function delete_(reportTemplateId: TprmReportTemplateId): Promise<"ok"> {
  const res = await jsonApi.delete_<"ok">(`/tprm/report_templates/${reportTemplateId}`);
  await invalidateQueries([q.tprm.reportTemplates.filter()]);
  return res;
}
