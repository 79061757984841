import {Json} from "../../api/jsonApi";
import {useClientAccount} from "./clientAccount";
import {AccountId} from "../../Types";
import {readLocalState, useLocalState} from "../../hooks/localState";
import {Static, StaticDecode, TSchema} from "@sinclair/typebox";
import {Dispatch, SetStateAction} from "react";

function clientAccountLocalStorageKey(accountId: AccountId, storageKey: string) {
  return `p4dclientaccountid-${accountId}:${storageKey}`;
}

type TJson<T extends TSchema> = Static<T> extends Json ? T : never;

export function useClientAccountLocalState<S extends TSchema>(
  storageKey: string,
  schema: TJson<S>,
  defaultValue: StaticDecode<S>,
): [StaticDecode<S>, Dispatch<SetStateAction<StaticDecode<S>>>] {
  const account = useClientAccount();
  return useLocalState(clientAccountLocalStorageKey(account.account_id, storageKey), schema, defaultValue);
}

export function readClientAccountLocalState<S extends TSchema>(
  accountId: AccountId,
  storageKey: string,
  schema: TJson<S>,
  defaultValue: StaticDecode<S>,
) {
  return readLocalState(clientAccountLocalStorageKey(accountId, storageKey), schema, defaultValue);
}
