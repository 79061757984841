import * as batshit from "@yornaath/batshit";

import api from "../../../api";
import {ScopeIdOrGlobal} from "../../../Products/VendorToolkit/scopes";
import {param, typedQuery} from "../../typing";
import {ScopeId} from "../../../Types";
import {resolver, scheduler} from "../../batch";

const batch = batshit.create({
  fetcher: api.vendorToolkit.scopes.load,
  resolver: resolver("scope_id"),
  scheduler,
});

export const scopeMins = typedQuery(["vendorToolkit", "scopes", "min"], async () => {
  return await api.vendorToolkit.scopes.listMin();
});

export const scopes = typedQuery(["vendorToolkit", "scopes"], async () => {
  return await api.vendorToolkit.scopes.list();
});

export const scope = typedQuery(["vendorToolkit", "scope", param<ScopeId>("scopeId")], async scopeId => {
  return await batch.fetch(scopeId);
});

export const scopeOwner = typedQuery(
  ["vendorToolkit", "scopeOwner", param<ScopeIdOrGlobal>("scopeId")],
  async scopeId => {
    return await api.vendorToolkit.scopes.getOwner(scopeId);
  },
);

export const scopeAxes = typedQuery(["vendorToolkit", "scopeAxes"], async () => {
  return await api.vendorToolkit.scopes.listAxes();
});
