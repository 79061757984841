import * as thirdParties from "./thirdParties";
import * as frameworks from "./frameworks";
import * as frameworkVersions from "./frameworkVersions";
import * as frameworkProfiles from "./frameworkProfiles";
import * as assessments from "./assessments";
import * as risks from "./risks";
import * as thirdPartyTiers from "./thirdPartyTiers";
import * as thirdPartyTags from "./thirdPartyTags";
import * as dataRooms from "./dataRooms";
import * as sources from "./sources";
import * as controls from "./controls";
import * as assessedControls from "./assessedControls";
import * as questionnaireTemplates from "./questionnaireTemplates";
import * as externalQuestionnaires from "./externalQuestionnaires";
import * as reportTemplates from "./reportTemplates";

const tprm = {
  thirdParties,
  thirdPartyTiers,
  thirdPartyTags,
  frameworks,
  frameworkVersions,
  frameworkProfiles,
  assessments,
  risks,
  dataRooms,
  sources,
  controls,
  assessedControls,
  questionnaireTemplates,
  externalQuestionnaires,
  reportTemplates,
};

export default tprm;
