import {PlatformedQueryFilters} from "../../";
import {nominate} from "../../../Types";

export * from "./question.ts";
export * from "./adhocQuestion.ts";
export * from "./questionnaire.ts";
export * from "./counterparty.ts";
export * from "./scope.ts";
export * from "./document.ts";
export * from "./documentSource.ts";
export * from "./task.ts";
export * from "./crmConnection.ts";
export * from "./questionSource.ts";
export * from "./deal.ts";
export * from "./fact.ts";
export * from "./esignatureProvider.ts";
export * from "./esignatureTemplate.ts";
export * from "./trustCenter.ts";
export * from "./asset.ts";
export * from "./graph.ts";
export * from "./librarySection.ts";
export * from "./coreResponse.ts";

export function filter(): PlatformedQueryFilters {
  return nominate("platformedQueryFilters", {queryKey: ["vendorToolkit"]});
}
